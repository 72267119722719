<!--
 * @Description: 名下任务项
 * @Author: ChenXueLin
 * @Date: 2021-10-18 11:41:19
 * @LastEditTime: 2022-08-25 15:36:05
 * @LastEditors: ChenXueLin
-->

<template>
  <div class="content-view-wrapper pd-10">
    <div
      class="content-view section-wrapper"
      ref="sectionWrapper"
      v-loading="loading"
      :element-loading-background="loadingBackground"
    >
      <table-title @resize="handleTableTitleResize">
        <template v-slot:searchForm>
          <el-form class="search-list" ref="searchForm" :model="searchForm">
            <el-form-item class="search-item--1" prop="scheduleInfoId">
              <el-input
                v-model="searchForm.scheduleInfoId"
                placeholder="调度信息ID"
                title="调度信息ID"
                maxlength="10"
                @input="
                  searchForm.scheduleInfoId = searchForm.scheduleInfoId.replace(
                    /[^0-9-]+/,
                    ''
                  )
                "
              ></el-input>
            </el-form-item>
            <el-form-item prop="taskSecondClass">
              <e6-vr-select
                v-model="searchForm.taskSecondClass"
                :data="taskTypeList"
                placeholder="任务单类型"
                title="任务单类型"
                clearable
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--1" prop="taskStatus">
              <e6-vr-select
                v-model="searchForm.scheduleInfoStatus"
                :data="scheduleStatusList"
                :props="{
                  id: 'codeValue',
                  label: 'codeName'
                }"
                placeholder="调度信息状态"
                title="调度信息状态"
                clearable
              ></e6-vr-select>
            </el-form-item>
            <el-form-item class="search-item--buttons">
              <el-button type="primary" @click="handleSearch">查询</el-button>
              <el-button class="reset" @click="handleReset">重置</el-button>
            </el-form-item>
          </el-form>
        </template>
      </table-title>
      <section class="table-wrapper">
        <el-table
          border
          :height="resizeViewHeight + 'px'"
          :data="tableData"
          highlight-current-row
        >
          <el-table-column
            show-overflow-tooltip
            v-for="(column, index) in columnData"
            :key="index"
            :prop="column.fieldName"
            :label="column.fieldLabel"
            :min-width="column.width"
            :fixed="column.fixed"
            :align="column.align"
            header-align="center"
          >
            <template slot-scope="{ row }">
              <span v-if="column.fieldName === 'taskNumber'">
                <el-button type="text" @click="toTaskOrder(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else-if="column.fieldName === 'scheduleTaskId'">
                <el-button type="text" @click="toScheduleTask(row)">
                  {{ row[column.fieldName] }}
                </el-button>
              </span>
              <span v-else>{{ row[column.fieldName] }}</span>
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- 分页 start -->
      <section class="pagination-wrapper fixed-section">
        <el-pagination
          :page-size.sync="searchForm.pageSize"
          :current-page.sync="searchForm.pageIndex"
          :page-sizes="pageSizes"
          :layout="layout"
          :total="total"
          background
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        ></el-pagination>
      </section>
      <!-- 分页 end -->
    </div>
  </div>
</template>

<script>
import base from "@/mixins/base";
import listPage from "@/mixins/list-page";
import listPageReszie from "@/mixins/list-page-resize";
import { findDownList, getScheduleInfoList } from "@/api";
import { printError } from "@/utils/util";
export default {
  name: "ownTask",
  components: {},
  data() {
    return {
      total: 0,
      loading: false,
      engineerrpid: "",
      taskTypeList: [], //工单类型
      scheduleStatusList: [], //调度信息状态下拉框
      searchForm: {
        scheduleInfoId: "", //调度任务ID
        taskSecondClass: "", //任务单类型
        scheduleInfoStatus: "", //调度信息状态
        pageIndex: 1,
        pageSize: 20
      },
      columnData: [
        {
          fieldName: "scheduleInfoId",
          display: true,
          fieldLabel: "调度信息ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleInfoStatusName",
          display: true,
          fieldLabel: "调度信息状态",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskNumber",
          display: true,
          fieldLabel: "所属任务单号",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "scheduleTaskId",
          display: true,
          fieldLabel: "所属调度任务ID",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskSecondClassName",
          display: true,
          fieldLabel: "任务单类型",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "corpName",
          display: true,
          fieldLabel: "客户名称",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "taskArrangedate",
          display: true,
          fieldLabel: "约定时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "signdate",
          display: true,
          fieldLabel: "签到时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishTime",
          display: true,
          fieldLabel: "完成时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "installCount",
          display: true,
          fieldLabel: "任务项总数",
          width: 140,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "noConfirmCount",
          display: true,
          fieldLabel: "待确认",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitWork",
          display: true,
          fieldLabel: "待作业",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "workingCount",
          display: true,
          fieldLabel: "作业中",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "finishCount",
          display: true,
          fieldLabel: "已完成",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "waitVerify",
          display: true,
          fieldLabel: "待审核",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "abnormalFinishCount",
          display: true,
          fieldLabel: "异常完成",
          width: 80,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createByName",
          display: true,
          fieldLabel: "创建人",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "createdTime",
          display: true,
          fieldLabel: "创建时间",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        },
        {
          fieldName: "remark",
          display: true,
          fieldLabel: "备注",
          width: 160,
          disabled: false,
          fixed: false,
          align: "center"
        }
      ], //表头
      tableData: []
    };
  },
  mixins: [base, listPage, listPageReszie],
  computed: {},
  watch: {},
  created() {
    let hasParamsRoute = this.getHasParamsRoute();
    this.engineerrpid = hasParamsRoute.params.engineerrpid;
    this.queryList();
    this.initData();
  },
  methods: {
    // 初始化数据(获取表头,表格数据)
    async initData() {
      try {
        let promiseList = [findDownList(["taskSecondClass", "scheduleStatus"])];
        let [taskTypeRes] = await Promise.all(promiseList);
        //工单类型
        this.taskTypeList = this.getFreezeResponse(taskTypeRes, {
          path: "data.taskSecondClass"
        });
        //调度信息状态下拉框
        this.scheduleStatusList = this.getFreezeResponse(taskTypeRes, {
          path: "data.scheduleStatus"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //获取名下任务项列表
    async queryList() {
      if (!this.engineerrpid) {
        this.$message.warning("无工程师ID, 请工程师详情重新进入页面。");
        return;
      }
      try {
        this.loading = true;
        if (!this.searchForm.scheduleInfoId) {
          delete this.searchForm.scheduleInfoId;
        }
        let res = await getScheduleInfoList({
          engineerrpid: this.engineerrpid,
          ...this.searchForm
        });
        this.tableData = this.getFreezeResponse(res, {
          path: "data.array"
        });
        this.total = this.getFreezeResponse(res, {
          path: "data.totalRecords"
        });
      } catch (error) {
        printError(error);
      } finally {
        this.loading = false;
      }
    },
    //跳转任务单
    toTaskOrder(row) {
      this.routerPush({
        name: "taskListManage/detail",
        params: {
          refresh: true,
          taskId: row.taskId
        }
      });
    },
    //跳转到调度任务详情
    toScheduleTask(row) {
      this.routerPush({
        name: "taskListManage/schedulingTaskDetails",
        params: {
          refresh: true,
          scheduleId: row.scheduleTaskId
        }
      });
    }
  }
};
</script>
<style lang="scss" scoped></style>
