var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-view-wrapper pd-10"},[_c('div',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],ref:"sectionWrapper",staticClass:"content-view section-wrapper",attrs:{"element-loading-background":_vm.loadingBackground}},[_c('table-title',{on:{"resize":_vm.handleTableTitleResize},scopedSlots:_vm._u([{key:"searchForm",fn:function(){return [_c('el-form',{ref:"searchForm",staticClass:"search-list",attrs:{"model":_vm.searchForm}},[_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"scheduleInfoId"}},[_c('el-input',{attrs:{"placeholder":"调度信息ID","title":"调度信息ID","maxlength":"10"},on:{"input":function($event){_vm.searchForm.scheduleInfoId = _vm.searchForm.scheduleInfoId.replace(
                  /[^0-9-]+/,
                  ''
                )}},model:{value:(_vm.searchForm.scheduleInfoId),callback:function ($$v) {_vm.$set(_vm.searchForm, "scheduleInfoId", $$v)},expression:"searchForm.scheduleInfoId"}})],1),_vm._v(" "),_c('el-form-item',{attrs:{"prop":"taskSecondClass"}},[_c('e6-vr-select',{attrs:{"data":_vm.taskTypeList,"placeholder":"任务单类型","title":"任务单类型","clearable":"","props":{
                id: 'codeValue',
                label: 'codeName'
              }},model:{value:(_vm.searchForm.taskSecondClass),callback:function ($$v) {_vm.$set(_vm.searchForm, "taskSecondClass", $$v)},expression:"searchForm.taskSecondClass"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--1",attrs:{"prop":"taskStatus"}},[_c('e6-vr-select',{attrs:{"data":_vm.scheduleStatusList,"props":{
                id: 'codeValue',
                label: 'codeName'
              },"placeholder":"调度信息状态","title":"调度信息状态","clearable":""},model:{value:(_vm.searchForm.scheduleInfoStatus),callback:function ($$v) {_vm.$set(_vm.searchForm, "scheduleInfoStatus", $$v)},expression:"searchForm.scheduleInfoStatus"}})],1),_vm._v(" "),_c('el-form-item',{staticClass:"search-item--buttons"},[_c('el-button',{attrs:{"type":"primary"},on:{"click":_vm.handleSearch}},[_vm._v("查询")]),_vm._v(" "),_c('el-button',{staticClass:"reset",on:{"click":_vm.handleReset}},[_vm._v("重置")])],1)],1)]},proxy:true}])}),_vm._v(" "),_c('section',{staticClass:"table-wrapper"},[_c('el-table',{attrs:{"border":"","height":_vm.resizeViewHeight + 'px',"data":_vm.tableData,"highlight-current-row":""}},_vm._l((_vm.columnData),function(column,index){return _c('el-table-column',{key:index,attrs:{"show-overflow-tooltip":"","prop":column.fieldName,"label":column.fieldLabel,"min-width":column.width,"fixed":column.fixed,"align":column.align,"header-align":"center"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
              var row = ref.row;
return [(column.fieldName === 'taskNumber')?_c('span',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toTaskOrder(row)}}},[_vm._v("\n                "+_vm._s(row[column.fieldName])+"\n              ")])],1):(column.fieldName === 'scheduleTaskId')?_c('span',[_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.toScheduleTask(row)}}},[_vm._v("\n                "+_vm._s(row[column.fieldName])+"\n              ")])],1):_c('span',[_vm._v(_vm._s(row[column.fieldName]))])]}}],null,true)})}),1)],1),_vm._v(" "),_c('section',{staticClass:"pagination-wrapper fixed-section"},[_c('el-pagination',{attrs:{"page-size":_vm.searchForm.pageSize,"current-page":_vm.searchForm.pageIndex,"page-sizes":_vm.pageSizes,"layout":_vm.layout,"total":_vm.total,"background":""},on:{"update:pageSize":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:page-size":function($event){return _vm.$set(_vm.searchForm, "pageSize", $event)},"update:currentPage":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"update:current-page":function($event){return _vm.$set(_vm.searchForm, "pageIndex", $event)},"size-change":_vm.handleSizeChange,"current-change":_vm.handleCurrentChange}})],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }